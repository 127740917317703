import { initCounter } from './assets/js/counter.js';

window.initializeSwiper = () => {
  if (window.Swiper != null) {
    const swiperThumb = new window.Swiper('.swiper-thumb', {
      spaceBetween: 10,
      slidesPerView: 4,
      allowTouchMove: true,
      freeMode: true,
      watchSlidesProgress: true,
    });

    const swiperContainer = new window.Swiper('.swiper', {
      spaceBetween: 10,
      allowTouchMove: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      thumbs: {
        swiper: swiperThumb,
      },
    });
  } else {
    console.warn("Swiper doesn't exist!");
  }
};

window.addEventListener('load', function () {
  initCounter();

  queueMicrotask(() => {
    window.initializeSwiper();

    // Contact sales form
    jQuery('#form-contact-sales').on('formSubmitSuccess', function () {
      jQuery('#bs-modal-contact-sales').modal('toggle');
    });
    jQuery('#form-contact-sales').on('formSubmitFailed', function () {
      SEMICOLON.Modules.notifications(document.querySelector('#form-contact-sales-error-message'));
    });
  });
});
