export function initCounter() {
    const counters = document.querySelectorAll('.counter');

    counters.forEach(counter => {
        const span = counter.querySelector('span');
        if (!span) return;

        const target = parseFloat(span.getAttribute('data-to')) || 0;
        const decimals = parseInt(span.getAttribute('data-decimals'), 10) || 0;
        const duration = parseInt(span.getAttribute('data-speed'), 10) || 1000;
        const comma = span.getAttribute('data-comma') === 'true';
        const sep = span.getAttribute('data-sep') || ',';
        const places = parseInt(span.getAttribute('data-places'), 10) || 3;

        const formatter = (value) => {
            value = value.toFixed(decimals);
            if (comma) {
                const reFormat = new RegExp(`\\B(?=(\\d{${places}})+(?!\\d))`, 'g');
                value = value.replace(reFormat, sep);
            }
            return value;
        };

        const updateCounter = () => {
            const start = 0;
            const end = target;
            const increment = end / (duration / 16);
            let current = start;

            const timer = setInterval(() => {
                current += increment;
                if (current >= end) {
                    current = end;
                    clearInterval(timer);
                }
                span.textContent = formatter(current);
            }, 16);
        };

        const observer = new IntersectionObserver((entries, observerInstance) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    updateCounter();
                    observerInstance.unobserve(entry.target);
                }
            });
        }, { rootMargin: '0px 0px 50px' });

        observer.observe(counter);
    });
}
